.section-container{
    margin: 12px 0;
    @include md{
        margin: 32px 0;
    }
    .section-heading{
        font-size: 2rem;
        &.center{
            text-align: center;
        }
    }
    .section-content{
        font-size: 1.1rem;
        font-family: $bold-font-family;
        font-weight: 300;
        @include md{
        font-size: 1.2rem;
        }
    }
}