.nsc23-page {

    .loader-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        position: fixed;
        background-color: whitesmoke;
    }
}