// Header
.event-modal{
    max-width:  420px;
    width: 100%;
    .ant-modal-body{padding: 0;}
}
.home-content{
    background-color: $dark-blue;
    padding-bottom: 24px !important;
    }
.header-container{
    border-bottom: $section-border;
    height: 100%;

    .header-image{
        width: 100%;
    }
    .header-bottom{
        @extend .saho-container ;
        padding-top: 0;
        padding-bottom: 0;
        .MuiGrid-spacing-xs-8{
            width: 100%;
            margin: 0;
        }
        .cta-grid{
            position: relative;
            width: inherit;
            padding: 10px 0;
            @include msm{
                margin-bottom: 40px;
            }
            .cta-container{
                position: relative;
                padding: 20px;
                margin: auto;
                width: inherit;
                border-radius:$card-border-radius;
                @include msm{
                    right: 0;
                    left: 0;
                    top: -25px;
                    padding-left: 40px;
                }
                .cta-message{
                    font-size: 24px;
                    font-family: $quicksand-font-family;
                    @include lg{
                        font-size: 42px;
                    }
                }
                .flex-container{
                    margin-top: 30px;
                    .cta-button{
                        @include primary-button;
                        background-color: $dark-blue;
                    }
                }
            }
        }
        .welcome-grid{
            padding: 10px 0;
            @include msm{
                padding-top: 0;
                padding-right: 0;
                padding-left: 32px;
            }
            h1{
                font-weight: 600;
                @include section-title;
                color: white;
            }
            p{
                color: white;
               @include section-content;
            }
        }
    }
}

// About
.about-container{
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: $section-border;
    background-color: #f2f7f9;
    @extend .saho-container ;
    p{
        text-align: justify;
    }
    .mobile-timeline{
        display: block;
        width: 100%;
        margin-top: 20px;
        @include mmd{
            display: none;
        }
    }
    .timeline-container{
        text-align: -webkit-center;
        .timeline{
            display: none;
            @include mmd{
                display: block;
            }
        }
    }
    .mv-container{
        margin-top: 40px;
    }
    .mission-title, .vision-title, .about-title{
        @include section-title;
        color: $dark-blue;
        font-weight: 600;
    }
    .mission-content, .vision-content, .about-content{
        @include section-content;
    }
}

// Member Schools 
.member-schools-container{
    @extend .saho-container ;
    .member-schools-title{
        @include section-title;
    }
    .slide-container{
        margin-right: 10px;
        .slide{
            width: 150px;
            height: 150px;;
        }
    }
}