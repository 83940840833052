.sticky-ad{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 2000;
    .ant-image{
        width: 100%;
        max-width: 600px;
        border: 2px solid;
    }
}