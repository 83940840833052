// Main Font-family
$main-font-family: "Roboto Condensed", serif, sans-serif;
$bold-font-family: "Roboto", serif, sans-serif;
$quicksand-font-family: 'Quicksand', sans-serif;


// $main-font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

// Font Weights
$light-font:200;
$regular-font:400;
$bold-font:600;
// Base Font
$base-font-weight: $regular-font;
$base-font-size:16px;
$base-line-height:1.4;
// Font Sizes
$xlarge-font:32px;
$large-font: 28px;
$title-font:18px;
$regular-font:16px;
$small-font: 14px;
$xsmall-font: 10px;
// Border Radius
$card-border-radius:2px;
$tag-border-radius:8px;
$button-border-radius:4px;
// Transition time
$transition-main:0.4s;
// Box Shadows
$main-box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);

// Borders
$main-border:1px solid $primary-blue;
$section-border: 5px solid $primary-white;