.breadcrumb-container{
    padding: 24px;
    box-shadow: none;
        // 320px window width and more
       .breadcrumb-link{
           text-decoration: none;
           color: $primary-blue;
           font-weight: 600;
       } 
       .breadcrumb-page{
            color: $primary-blue;
       }
}