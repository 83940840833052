.blog-page{
    max-width: 1400px;
    margin: auto;
    @extend .saho-container ;
    .blog-header{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .blog-title{
            font-size: 2.2rem;
            font-weight: 600;
            line-height: 2.4rem;
            @include md{
            font-size: 2.4rem;
            }
        }
        .meta-info{
            display: flex;
            justify-content: space-between;
            .author{
                text-align: center;
                padding-right: 12px;
                border-right: 1px solid;
            }
            .date{
                text-align: center;
                padding-left: 12px;
            }
            .author, .date{
                font-size: 1.2rem;
                color: rgba(0, 0, 0, 0.45);
            }
        }
    }
}