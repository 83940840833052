.footer{
    background: $primary-black;
    padding: 24px 14px;
    @include xs{
        padding: 32px 16px;
    }
    @include md{
        padding: 44px 80px;
    }
    .copyright{
        justify-content: center;
        color: white;
        display: flex;
        text-align: center;
    }
    hr{
        color: $primary-blue;
        margin: 14px;
        border: 1px solid;
        @include xs{
            margin: 24px 16px;
        }
        @include md{
            margin: 24px 80px;
        }
    }
    .footer-logo-container{
        margin: auto;
        padding: 0;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        .saho-footer-logo{
            background-color: white;
            padding: 12px;
            width: 200px;
            border-top-right-radius: 4px;
            border-top-left-radius: 4px;
            box-shadow: $main-box-shadow;
        }
        .saho-footer-g20-logo {
            width: 200px;
            border-radius: 4px;
            box-shadow: $main-box-shadow;
            margin-top: 12px;
        }
        .cta-button{
            @include primary-button;
            width: 200px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;

        }
    }

    .title-container{
        width: 100%;
        text-align: center;
        @include msm{
            text-align: unset;
            max-width: 150px;
        }
        &.archive{
            @include msm{
            text-align: unset;
                max-width: 100px;
            }
        }
        .underline{
            max-width: inherit;
            margin-bottom: 20px;
            margin-top: 2px;

        }
    }
    .footer-subtitle{
        color: white;
        font-size: 24px;
        margin-top: 12px;
    }
    .footer-menu-container{
        display: flex;
        flex-direction: column;
        text-align: center;
        @include msm{
            display: inline;
        }
        a{
            display: inline;
            text-decoration: none;
        }
        button{
            @include msm{
                margin-right: 25px;
            }
            font-size: 20px;
            font-weight: 400;
            text-transform: capitalize;
            color: white;
            border-radius: 0;
            transition: $transition-main;
            &:hover{
                color: $primary-blue;
                transition: $transition-main;
            }
        }
    }
    .footer-archives-container{
        display: flex;
        flex-direction: column;
        text-align: center;

        @include msm{
            flex-wrap: wrap;
            flex-direction: row;
            text-align: unset;
        }
        a{
            display: block;
            text-decoration: none;
            margin-bottom: 12px;
        }
        button{
            @include msm{
                margin-right: 25px;
            }
            font-size: 20px;
            font-weight: 400;
            text-transform: capitalize;
            color: white;
            border-radius: 0;
            transition: $transition-main;
            &:hover{
                color: $primary-blue;
                transition: $transition-main;
            }
        }
    }
}