@import '~antd/dist/antd.css';
@import '~video-react/styles/scss/video-react';

@import './utilities/breakpoints';
@import './utilities/colors';
@import './utilities/mixins';
@import './utilities/variables';

@import './base/base';

@import './pages/home';
@import './pages/about';
@import './pages/gallary';
@import './pages/archives';
@import './pages/blogs';
@import './pages/blogs-page';
@import './pages/event';
@import './pages/nsc23';


@import './components/navbar';
@import './components/footer';
@import './components/sections';
@import './components/next-prev';
@import './components/sidebar';
@import './components/breadcrumb';
@import './components/blogs-banner';
@import './components/blog-card';
@import './components/sticky-ad';
@import './components/event-modal';





