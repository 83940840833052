.grow {
    flex-grow: 1;
}

.flex-end {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

#navbar {
    background: $dark-blue;
    height: 65px;

    @include sm {
        height: 70px;
    }

    .MuiToolbar-regular {
        padding: 0 16px;

        @include md {
            padding: 0 80px;
        }

        .menu-button {
            color: white;
            @include mr-10;
            display: block;

            @include xl {
                display: none;
            }
        }

        h2 {
            font-size: $small-font;
            color: white;

            @include sm {
                font-size: $title-font;
            }
        }

        .navbar-title {
            text-decoration: none;
            color: white;
        }

        .logo-container {
            // flex-grow: 0;

            @include xl {
                // flex-grow: 1;
                // position: absolute;
            }

            img {
                width: auto;
                height: 50px;

                // @include sm {
                //     width: 80px;
                // }
            }
        }

        .nav-menu-container {
            display: none;

            @include xl {
                display: block;
            }

            a {
                text-decoration: none;

                &.active {
                    button {
                        border-bottom: 2px solid white;
                        transition: $transition-main;
                    }
                }
            }

            .nav-button {
                margin-left: 25px;
                text-transform: capitalize;
                color: white;
                border-radius: 0;
                transition: $transition-main;

                &:hover {
                    border-bottom: 2px solid white;
                    transition: $transition-main;
                }
            }
            .register-button {
                @include primary-button;
                background: $primary-yellow;
                margin-left: 12px
            }
        }
    }

    .banner {
        background: $primary-yellow;
        justify-content: flex-end;
        padding: 6px 16px;
        display: flex;
        justify-content: center;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        position: fixed;
        bottom: 0;
        width: 100%;
        text-transform: uppercase;
        font-weight: 600;
        @include xl {
            display: none;
        }
    }

}

.menu-container#fade-menu {
    top: 65px !important;

    a {
        text-decoration: none;
        color: $primary-blue;
    }
}