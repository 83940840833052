.events-page{
    @extend .saho-container ;
    background-color: #f2f7f9;
background-image: url("data:image/svg+xml,%3Csvg width='40' height='1' viewBox='0 0 40 1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h20v1H0z' fill='%231ca6f6' fill-opacity='0.15' fill-rule='evenodd'/%3E%3C/svg%3E");
}
.activities{
    @include section-title;
    font-size: 24px;
}
.event-container{
    .event-subtitle{
        color: $dark-blue;
        font-size: 20px;
        margin-bottom: 10px;
    }
    .event-description{
        @include section-content;
        font-size: $regular-font;
        margin-bottom: 24px;
    }
}